import React from 'react'
import {
  Brain,
  Top,
  Intro,
  MainCol,
  CloudSmall,
  CloudLarge,
} from '../components/Braindump'
import Layout from '../components/layout'
import Links from '../components/BrainDumpLinks'
import SEO from '../components/seo'
import { useStaticQuery, graphql } from 'gatsby'

const About = () => {
  const data = useStaticQuery(
    graphql`
      query {
        topImage: file(relativePath: { eq: "braindump-new.png" }) {
          childImageSharp {
            fluid(maxWidth: 755, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }

        cloudSmall: file(relativePath: { eq: "cloud-small.png" }) {
          childImageSharp {
            fluid(maxWidth: 150, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        cloudLarge: file(relativePath: { eq: "cloud-large.png" }) {
          childImageSharp {
            fluid(maxWidth: 250, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <SEO title="braindump" />
      <Top>
        <MainCol>
          <h1>braindump</h1>

          <Brain
            fluid={data.topImage.childImageSharp.fluid}
            alt="Mmmm Brainssss"
            critical={true}
            fadeIn={false}
          />
          <CloudSmall
            fluid={data.cloudSmall.childImageSharp.fluid}
            critical={true}
            fadeIn={false}
            topright
          />
          <CloudSmall
            fluid={data.cloudSmall.childImageSharp.fluid}
            critical={true}
            fadeIn={false}
          />
        </MainCol>
      </Top>

      <MainCol>
        <Intro>
          here’s a few links to things that’ve helped me grow my skills over the
          years, they may help you too!
        </Intro>
        <CloudLarge
          fluid={data.cloudLarge.childImageSharp.fluid}
          critical={true}
          fadeIn={false}
        />
      </MainCol>

      <Links />
      <MainCol>
        <CloudLarge
          fluid={data.cloudLarge.childImageSharp.fluid}
          critical={true}
          fadeIn={false}
          bottomLeft
        />
      </MainCol>
    </Layout>
  )
}

export default About
