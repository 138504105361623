import React from 'react'
import styled from 'styled-components'

const Links = () => (
  <Content>
    <Column>
      
      <Heading>learn</Heading>
      <ul>
        <li><a href="https://www.schoolofmotion.com/" target="_blank">school of motion</a></li>
        <li><a href="https://motiondesign.school/" target="_blank">motion design school</a></li>
        <li><a href="https://www.skillshare.com/" target="_blank">skillshare</a></li>
        <li><a href="https://www.mographmentor.com/" target="_blank">mograph mentor</a></li>
        <li><a href="https://www.domestika.org/" target="_blank">domestika</a></li>
        <li><a href="https://www.patataschool.com/" target="_blank">patata school</a></li>
        <li><a href="https://motionhatch.com/" target="_blank">motion hatch</a></li>
        <li><a href="https://www.youtube.com/c/BenMarriott" target="_blank">ben marriot</a></li>
        <li><a href="https://www.holdframe.com/" target="_blank">holdframe</a></li>
        <li><a href="https://dotlung.com/" target="_blank">dot lung</a></li>
      </ul>

      <Heading color="#ffbe1d">communities</Heading>
      <ul>
        <li><a href="https://www.thenode.is/" target="_blank">node</a></li>
        <li><a href="http://www.panimation.tv/" target="_blank">panimation</a></li>
        <li><a href="https://buckuback.com/" target="_blank">buckuback</a></li>
        <li><a href="https://www.creativechamps.co/" target="_blank">creative champs</a></li>
        <li><a href="https://ozanimate.com/" target="_blank">ozanimate</a></li>
        <li><a href="https://motionographer.com/" target="_blank">motionographer</a></li>
        <li><a href="http://www.mastersofmotion.com.au/" target="_blank">masters of motion</a></li>
        <li><a href="https://animade.tv/articles/introducing-all-ears" target="_blank">all ears</a></li>
        <li><a href="https://www.loopdeloop.org/" target="_blank">loopdeloop</a></li>
        <li><a href="https://www.theloop.com.au/" target="_blank">the loop</a></li>
      </ul>

      <Heading color="#019755">inspo</Heading>
      <ul>
        <li><a href="https://never-not-working.tumblr.com/" target="_blank">never not working</a></li>
        <li><a href="https://www.itsnicethat.com/" target="_blank">it's nice that</a></li>
        <li><a href="https://goodmoves.tv/" target="_blank">goodmoves</a></li>
        <li><a href="https://www.underconsideration.com/brandnew/" target="_blank">brand new</a></li>
        <li><a href="https://www.creativeboom.com/" target="_blank">creative boom</a></li>
        <li><a href="https://theinspirationgrid.com/" target="_blank">inspiration grid</a></li>
      </ul>

      <Heading color="#F790CE">podcasts</Heading>
      <ul>
        <li><a href="https://podcasts.apple.com/au/podcast/creative-pep-talk/id929743897" target="_blank">creative pep talk</a></li>
        <li><a href="https://podcasts.apple.com/au/podcast/brand-your-passion/id1521589218" target="_blank">brand your passion</a></li>
        <li><a href="https://podcasts.apple.com/au/podcast/school-of-motion-podcast/id1211312212" target="_blank">school of motion podcast</a></li>
        <li><a href="https://podcasts.apple.com/au/podcast/the-meaning-movement-helping-you-find-your-calling/id1152705177" target="_blank">the meaning movement</a></li>
        <li><a href="https://podcasts.apple.com/au/podcast/masters-of-motion/id1173390344" target="_blank">masters of motion</a></li>
        <li><a href="https://podcasts.apple.com/au/podcast/99-invisible/id394775318" target="_blank">99% invisible</a></li>
        <li><a href="https://podcasts.apple.com/au/podcast/how-i-work/id1415266924" target="_blank">how i work</a></li>
    
      </ul>

      <Heading color="#F5591B">plugins</Heading>
      <ul>
        <li><a href="https://aescripts.com/" target="_blank">ae scripts</a></li>
        <li><a href="https://eyedesyn.com/" target="_blank">eyedesyn</a></li>
        <li><a href="https://www.battleaxe.co/" target="_blank">battle axe</a></li>
        <li><a href="https://rxlaboratory.org/" target="_blank">rxlaboratory</a></li>
        <li><a href="https://www.videocopilot.net/" target="_blank">video copilot</a></li>
      </ul>

      <Heading color="#A0E1F1">books</Heading>
      <ul>
        <li><a href="https://www.booktopia.com.au/the-animator-s-survival-kit-richard-williams/book/9780865478978.html?dsa=s1-east&gclid=CjwKCAjw9e6SBhB2EiwA5myr9jZ37H9bFBVGCGLMcm41MDmPc4i1lPxHgbaFw-HlVxzplzg8aXCy_hoCV5wQAvD_BwE" target="_blank">the animator's survival kit</a></li>
        <li><a href="https://www.booktopia.com.au/illusion-of-life-ollie-johnston/book/9780786860708.html" target="_blank">illusion of life</a></li>
        <li><a href="https://www.booktopia.com.au/character-animation-crash-course--eric-goldberg-/book/9781879505971.html" target="_blank">character animation crash course</a></li>
        <li><a href="https://www.booktopia.com.au/anatomy-drawing-school-andras-szunyoghy/book/9783848010080.html" target="_blank">anatomy drawing school</a></li>
        <li><a href="https://www.booktopia.com.au/cartoon-animation-with-preston-blair-preston-blair/book/9781633228900.html" target="_blank">cartoon animation</a></li>
        <li><a href="https://www.booktopia.com.au/the-freelance-manifesto-joey-korenman/book/9781619616714.html" target="_blank">freelance manifesto</a></li>
        <li><a href="https://www.booktopia.com.au/design-for-motion-austin-shaw/book/9781138318656.html" target="_blank">design for motion</a></li>
        <li><a href="https://www.dymocks.com.au/book/timing-for-animation-and-40th-anniversary-edition-by-harold-whitaker-and-john-halas-and-tom-sito-9780367527754" target="_blank">timing for animation</a></li>
      </ul>

    </Column>
  </Content>
)
export default Links

const Heading = styled.h3`
  color: ${props => props.color || '#60D3F4'};
  font-size: 30px;
  margin-top: 0;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 50px;
    margin-bottom: 30px;

  }
`

const Content = styled.div`
  background: #26252D;
  text-align: center;
  padding: 60px 0;

  @media (min-width: 1024px) {
    padding: 120px 0;
  }
  a {
    color: #e8d8d9;
    font-size: 18px;
    &:hover {
      text-decoration: none;
    }

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }
  ul {
    padding: 0;
    margin: 0 0 50px;

    @media (min-width: 768px) {
      margin-bottom: 100px;
    }

    &:last-of-type {
      margin-bottom: 20px;
    }
  }
  li {
    margin-bottom: 7px;
  }
`
const Column = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;

  @media (max-width: 1200px) {
    padding: 0;
  }
`
