import styled from 'styled-components'
import Img from 'gatsby-image'

export const Top = styled.div`
  background: #ffbe1d;
  padding-top: 20px;

  @media (min-width: 1024px) {
    padding-top: 50px;
  }

  h1 {
    margin: 0;
    text-align: center;
    font-size: calc(0.15 * 100vw);
    margin-bottom: calc(-0.06 * 100vw);
    color: #fff;

    @media (min-width: 1200px) {
      font-size: 200px;
      margin-bottom: -75px;
    }
  }
`

export const MainCol = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;

  @media (min-width: 1240px) {
    padding: 0;
  }
`

export const CloudSmall = styled(Img)`
  width: 60px;
  position: absolute !important;
  left: 0;
  bottom: 0;
  transform: translateY(50%);

  @media (min-width: 1024px) {
    width: 102px;
    margin-top: -10px;
    bottom: 10px;
    left: -10px;
  }

  ${props =>
    props.topright &&
    `
    left: auto;
    right: 25vw;
    top: -40px;
    bottom: auto;

    @media(min-width: 768px) {
      top: -20px;
      }
    @media(min-width: 1024px) {
      left: auto;
      bottom: auto;
      top: -30px;
      right: 25vw;
    }
    @media(min-width: 1200px) {
      top: -20px;
      right: 260px;
    }
  `};
`

export const CloudLarge = styled(Img)`
  width: 120px;
  position: absolute !important;
  right: 0;
  bottom: -67px;

  @media (min-width: 1024px) {
    width: 217px;
    bottom: -110px;
    right: 20px;
  }

  ${props =>
    props.bottomLeft &&
    `
    right: auto;
    left: 0;
    bottom: -50px;  


    @media(min-width: 1024px) {
      bottom: -88px;  
    }
    @media(min-width: 1200px) {
    }
  `};
`
export const Brain = styled(Img)`
  max-width: 657px;
  margin: 0 auto;
  width: 90%;
  z-index: 10;
  position: relative;

  @media (min-width: 768px) {
    width: 100%;
  }
`

export const Intro = styled.p`
  font-family: ${({ theme }) => theme.headingFont};
  text-align: center;
  font-size: calc(0.048 * 100vw);
  margin: 0;
  padding: 30px 0;
  position: relative;

  @media (min-width: 768px) {
    padding: 70px 0;
  }

  @media (min-width: 1200px) {
    font-size: 54px;
    padding: 75px 0;
  }
`
